import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import NewMeeting from "../components/newMeeting"

const IndexPage = () => (
  <StaticQuery 
    query={graphql`
      query HomePageQuery {
        contentfulPage(pageName: {eq: "Home"}) {
          id
          pageContent {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                title
                gatsbyImageData
              }
            }
          }
        }
        allContentfulEventSummary(limit: 1, sort: {order: DESC, fields: eventDate}) {
          edges {
            node {
              id
              eventDate
              excerpt
              slug
              title
              coverImage {
                contentful_id
                title
                gatsbyImageData(width: 200)
              }
              summary {
                references {
                  __typename
                  contentful_id
                  title
                  gatsbyImageData
                }
                raw
              }
            }
          }
        }
      }  
    `}

    render = {data => {  
      const Bold = ({ children }) => <span className="bold">{children}</span>
      const Text = ({ children }) => <p className="align-center">{children}</p>
      const eventDate = new Date(data.allContentfulEventSummary.edges[0].node.eventDate)
      const eventCoverImage = getImage(data.allContentfulEventSummary.edges[0].node.coverImage)
      
      const options = {
        renderMark: {
          [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
          [BLOCKS.EMBEDDED_ASSET]: node => {
            const image = getImage(node.data.target)
            return (
              <>
                <GatsbyImage image={image} alt={node.data.target.title} style={{display: 'block', margin: '0 auto'}} />
              </>
            )
          },
        },
      }
      
      return(
        <>
          <Seo title="Home" />
          {renderRichText(data.contentfulPage.pageContent, options)}
          <div class="card" style={{margin: '10px 0 10px 0'}}>
            <div class="card-body">
              <h5 class="card-title">{data.allContentfulEventSummary.edges[0].node.title}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{eventDate.toDateString()}</h6>
              <p class="card-text">
                <GatsbyImage image={eventCoverImage} alt={data.allContentfulEventSummary.edges[0].node.coverImage.title} style={{float: 'left', margin: '0 20px 0 0'}} /> 
                <div style={{display: 'block'}} >
                  {data.allContentfulEventSummary.edges[0].node.excerpt}
                </div>
              </p>
              <Link to="/events" class="btn btn-primary">More</Link>
            </div>
          </div>
          <NewMeeting />
        </>
      )
    }}
  />
)

export default IndexPage
